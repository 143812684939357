// Generated by CoffeeScript 2.7.0
/* eslint-disable */

(function () {
  var base;

  window.Lanalytics || (window.Lanalytics = {});

  (base = window.Lanalytics).Model || (base.Model = {});

  window.Lanalytics.Model.ExpApiStatement = class ExpApiStatement {
    constructor(
      user,
      verb,
      resource,
      timestamp = new Date(),
      with_result = {},
      in_context = {},
    ) {
      this.with_result = with_result;
      this.in_context = in_context;
      if (!(user instanceof Lanalytics.Model.StmtUser)) {
        throw "'user' argument cannot be nil and should be Lanalytics::Model::StmtUser";
      }
      this.user = user;
      if (!(verb instanceof Lanalytics.Model.StmtVerb)) {
        throw "'verb' argument cannot be nil and should be Lanalytics::Model::StmtVerb";
      }
      this.verb = verb;
      if (!(resource instanceof Lanalytics.Model.StmtResource)) {
        throw "'resource' argument cannot be nil and should be Lanalytics::Model::StmtResource";
      }
      this.resource = resource;
      timestamp || (timestamp = DateTime.now);
      if (!(timestamp instanceof Date || timestamp instanceof String)) {
        throw "'timestamp' argument should be DateTime or String";
      }
      if (timestamp instanceof String) {
        timestamp = new Date(timestamp);
      }
      this.timestamp = timestamp;
    }

    toJSON() {
      return JSON.stringify({
        events: [
          {
            user: this.user.params(),
            verb: this.verb.params(),
            resource: this.resource.params(),
            timestamp: this.timestamp,
            result: this.with_result,
            context: this.in_context,
          },
        ],
      });
    }
  };
}).call(this);
